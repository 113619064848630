// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//import assets
import '../assets/styles/components/header.scss'
import {ReactComponent as Logo} from '../assets/images/Logo.svg';

// Import Components
import {Dropdown, Menu} from "antd";
import {BurgerIcon, CloseIcon} from "../assets/images";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {Link, NavLink, useLocation} from "react-router-dom";
import {ChangeLanguage} from "../redux/actions";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";
import {history} from "../configs/history";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";

function Header(props) {
    const {staticTexts,selectedLanguage,languages,toggleLanguage} = props;
    // const [closeLanguage, setCloseLanguage] = useState(false)
    // useEffect(() => {
    //     window.addEventListener("scroll",() => setCloseLanguage(true))
    // },[])

    let location = useLocation()

    console.log(selectedLanguage,'selectedLanguage')
    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.id}
                                   onClick={() => {
                                       history.push(`/${item.code}${history.location?.pathname?.slice(3)}`)
                                       props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <span>{item?.name}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );

    let fixe = props.mobileMenuIsOpen
    let hide = location.pathname.includes('/home')
    /*todo color հասկանալի չի ինչի համարա փոխի անունը*/
    /*todo ինչի՞ ask-logo, փոխել*/
    return <div className={`header-wrapper ${hide ? 'backgroundColor' : ''} ${fixe ? 'fixe' : ''}`}>
        <Link to={'/'}>
            <div className={'sos-logo'}>
                <Logo title={''}/>
            </div>
        </Link>
        <ul className="nav-menu">
            <NavLink to={getUrlWithLocal('/home')} activeClassName='active'>
                <li className="nav-item">{staticTexts?.header_item_home}</li>
            </NavLink>
            <NavLink to={getUrlWithLocal('/about')} activeClassName='active'>
                <li className="nav-item">{staticTexts?.header_item_about}</li>
            </NavLink>
            <NavLink to={getUrlWithLocal('/work')} activeClassName='active'>
                <li className="nav-item">{staticTexts?.header_item_work}</li>
            </NavLink>
            <NavLink to={getUrlWithLocal('/partners')} activeClassName='active'>
                <li className="nav-item">{staticTexts?.header_item_staff}</li>
            </NavLink>
            <NavLink to={getUrlWithLocal('/services')} activeClassName='active'>
                <li className="nav-item">{staticTexts?.header_item_services}</li>
            </NavLink>
            <NavLink to={getUrlWithLocal('/contacts')} activeClassName='active'>
                <li className="nav-item">{staticTexts?.header_item_contact}</li>
            </NavLink>
            {/*todo overlay={menuLanguage} նայի antd-ում ձևը փոխվելա*/}
            <li className="nav-item">
                <Dropdown overlay={menuLanguage}
                          trigger={['hover']}
                          // overlayClassName={`${closeLanguage ? "close" : ""}`}
                          // onClick={() => toggleLanguage(setCloseLanguage(false))}
                          placement={'bottom'}>
                    <a className="ant-dropdown-link">
                        <div className="selected-language">
                            {/*<span>{selectedLanguage?.name}</span>*/}
                            <div className={'language_icon'}>
                                <img src={generateImageMediaUrl(selectedLanguage?.iconPath?.path)}/>
                            </div>
                            <div>
                            </div>
                        </div>
                    </a>
                </Dropdown>
            </li>
            <a className={`header_burger ${!props.mobileMenuIsOpen ? 'open-menu' : 'close-menu'}`}
               onClick={props.toggleMobileMenu}>
                {!props.mobileMenuIsOpen ? <BurgerIcon/> : <CloseIcon/>}
                </a>
        </ul>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'languages',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = {ChangeLanguage};
export default connect(mapStateToProps,mapDispatchToProps)(Header);
